<template>
  <div>
    <!-- Page content -->
    <b-container class="mt-7 pb-5">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <b-card no-body class="bg-secondary border-0 mb-0">
            <b-card-body class="px-lg-5 py-lg-5">
              <validation-observer
                v-slot="{ handleSubmit }"
                ref="formValidator"
              >
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <base-input
                    alternative
                    class="mb-3"
                    name="username"
                    :rules="{ required: true }"
                    prepend-icon="ni ni-single-02"
                    :placeholder="$t('Username')"
                    v-model="model.username"
                  >
                  </base-input>

                  <base-input
                    alternative
                    class="mb-3"
                    name="password"
                    :rules="{ required: true, min: 6 }"
                    prepend-icon="ni ni-lock-circle-open"
                    type="password"
                    :placeholder="$t('Password')"
                    v-model="model.password"
                  >
                  </base-input>

                  <div class="text-center">
                    <base-button
                      type="primary"
                      native-type="submit"
                      class="my-4"
                      >{{ $t('Login') }}</base-button
                    >
                  </div>
                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { Form } from 'element-ui';
import { getStore, setStore, removeStore } from "../../util/helper";

const axios = require("axios");

export default {
  data() {
    return {
      model: {
        email: "",
        password: ""
      }
    };
  },
  mounted() {
    // Clear last login information
    setStore('token', '');
    setStore('user_id', '');
    setStore('username', '');
  },
  methods: {
    onSubmit() {
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append('username', this.model.username);
      bodyFormData.append('password', this.model.password);
      var that=this;
      axios({
        method: 'post',
        url: "/Api/Web/Login",
        data: bodyFormData
        })
        .then(function(response) {
          if (response.status == '200') {
            var rt = response.data;
            if (rt.status == 0) {
              // local storage saves only DOMString format
              setStore('token', rt.data.token);
              setStore('user_id', rt.data.user_id);
              if (rt.data.is_retail) {
                setStore('is_retail', rt.data.is_retail);
                // console.log("Retail");
              } else {
                removeStore('is_retail');
                // console.log("Not Retail");
              }
              setStore('route_names', rt.data.route_names);
              setStore('element_utility', rt.data.element_utility);
              setStore('element_visibility', rt.data.element_visibility);
              setStore('username', that.model.username);
              if (rt.data.is_retail) {
                that.$router.push("/product");
              } else {
                that.$router.push("/item_menu");
              }
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function(error) {
          // console.log(error);
          that.$router.push("/login");
        });
    }
  }
};
</script>
